//
// Co3
//
// Copyright:: (c) 2023 Innotronic Ingenieurbüro GmbH

// Hotwire
import { Turbo } from "@hotwired/turbo-rails";

// Stimulus controllers
import "./controllers";

// Bootstrap
import * as bootstrap from "bootstrap";
document.addEventListener( 'turbo:load', e =>
{
  document.querySelectorAll( '[data-bs-toggle="popover"]' ).forEach( elt => { new bootstrap.Popover( elt ); } );
});

// Font Awesome
import '@fortawesome/fontawesome-free/js/all';
FontAwesome.config.mutateApproach = 'sync'

// Action Text
import "trix"
import "@rails/actiontext"

// FancyBox
import { Fancybox } from '@fancyapps/ui';
document.addEventListener( 'turbo:load', e =>
{
  document.body.addEventListener( 'click', Fancybox.fromEvent, false );
});