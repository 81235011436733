//
// Co3
//
// Copyright:: (c) 2023 Innotronic Ingenieurbüro GmbH

import { application } from "./application"

// import BeepController from "./beep_controller"
// application.register( 'beep', BeepController )

import BenutzerPasswordController from "./benutzer_password_controller"
application.register( 'benutzer-password', BenutzerPasswordController )

import DevController from "./dev_controller"
application.register( 'dev', DevController )

import DialogController from "./dialog_controller"
application.register( 'dialog', DialogController )

import FileFieldController from "./file_field_controller"
application.register( 'file-field', FileFieldController )

import FileUploadController from "./file_upload_controller"
application.register( 'file-upload', FileUploadController )

import IpeController from "./ipe_controller"
application.register( 'ipe', IpeController )

import ListController from "./list_controller"
application.register( 'list', ListController )

import NotificationController from "./notification_controller"
application.register( 'notification', NotificationController )

import PdfController from "./pdf_controller"
application.register( 'pdf', PdfController )

import SelectController from "./select_controller"
application.register( 'select', SelectController )

import TabController from "./tab_controller"
application.register( 'tab', TabController )

import TableController from "./table_controller"
application.register( 'table', TableController )

import TagsController from "./tags_controller"
application.register( 'tags', TagsController )